import {Action} from '@ngrx/store';
import {User} from '../models/user';

export const SIGN_UP = 'SIGN_UP';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';

export class Signup implements Action {
  readonly type = SIGN_UP;

  constructor(public payload: User) {
  }
}

export class Login implements Action {
  readonly type = LOGIN;

  constructor(public payload: User) {
  }
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export type AuthActions = Signup | Login | Logout;
