// Created by soban on 29-08-2018.

export class AppError {
  visibility = false;
  message = 'Unfortunately, some error occurred. Please try again!';

  constructor(visibility?: boolean, message?: string) {
    if (visibility) {
      this.visibility = visibility;
    }
    if (message) {
      this.message = message;
    }
  }
}
