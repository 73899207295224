import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing/landing.component';
import {LayoutComponent} from './layout/layout.component';
import {TermsComponent} from './terms/terms.component';
import { BookNowComponent } from './book/book.component';
import {PrivacyComponent} from './privacy/privacy.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {path: '', component: LandingComponent},
      {path: 'terms-&-conditions', component: TermsComponent},
      {path: 'privacy-policy', component: PrivacyComponent},
      {path: 'book-now', component: BookNowComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WebRoutingModule { }
