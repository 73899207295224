// Created by soban on 08-08-2017.

export class AppConfig {
  public static readonly DEBUG = false;
  public static readonly NUMBER_PRECISION = 2;
  private static readonly URL_PROD = 'https://saker-server.rockvillegroup.com';
  private static readonly URL_DEV = 'http://localhost:3000';
  private static _URL = '';
  public static PAGE_SIZE = 10;

  static initialize() {
    AppConfig._URL = (AppConfig.DEBUG) ? AppConfig.URL_DEV : AppConfig.URL_PROD;
  }

  static get URL(): string {
    return this._URL;
  }
}

AppConfig.initialize();
