import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromAuth from '../../store/auth.reducers';
import * as fromApp from '../../store/app.reducers';
import {map, take} from 'rxjs/operators';

@Injectable()
export class AnonymousGuardService implements CanActivate {

  constructor(private router: Router,
              private store: Store<fromApp.AppState>) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.store.select('auth')
      .pipe(take(1),
        map((auth: fromAuth.State) => {
          if (!auth.isAuthenticated) {
            return true;
          } else {
            // logged in so redirect to page with the return url
            this.router.navigate(['/']);
            return false;
          }
        }));
  }
}
