import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../../environments/environment';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {reducers} from '../store/app.reducers';
import {StoreModule} from '@ngrx/store';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AnonymousGuardService} from './services/anonymous-guard.service';
import {AuthGuard} from './services/auth-guard.service';
import {DefaultInterceptor} from './httpHelpers/tokenInsertion.interceptor';
import {SpinnerComponent} from './spinner/spinner.component';
import {ErrorComponent} from './error/error.component';
import {LoaderComponent} from './loader/loader.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxScrollToService} from './services/ngx-scroll-to.service';

const storeModules: any = [
  StoreModule.forRoot(reducers),
  StoreRouterConnectingModule,
  !environment.production ? StoreDevtoolsModule.instrument() : [],
];

const modules: any = [
  CommonModule,
  BrowserAnimationsModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
];
const components: any = [
  LoaderComponent,
  ErrorComponent,
  SpinnerComponent,
];

@NgModule({
  imports: [
    ...modules,
    ...storeModules
  ],
  declarations: [...components],
  exports: [...components, ...modules],
  providers: [
    AuthGuard,
    AnonymousGuardService,
    NgxScrollToService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
