import {Injectable} from '@angular/core';
import {ScrollToService, ScrollToConfigOptions} from '@nicky-lenaers/ngx-scroll-to';

@Injectable()
export class NgxScrollToService {

  constructor(private _scrollToService: ScrollToService) {
  }

  public triggerScrollTo(destination, offset = 0) {

    const config: ScrollToConfigOptions = {
      target: destination,
      offset: offset,
      duration: 2000
    };

    this._scrollToService.scrollTo(config);
  }
}
