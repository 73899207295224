import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebRoutingModule } from './web-routing.module';
import {LandingComponent} from './landing/landing.component';
import { LayoutComponent } from './layout/layout.component';
import { TermsComponent } from './terms/terms.component';
import { BookNowComponent } from './book/book.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import { PopupComponent } from './popup/pop.component';

@NgModule({
  imports: [
    CommonModule,
    ScrollToModule.forRoot(),
    WebRoutingModule
  ],
  declarations: [LandingComponent, LayoutComponent, TermsComponent,BookNowComponent,PrivacyComponent, HeaderComponent, FooterComponent,PopupComponent]
})
export class WebModule { }
