import * as AuthActions from './auth.actions';
import {User} from '../models/user';

export interface State {
  user: User;
  isAuthenticated: boolean;
}

const user = JSON.parse(localStorage.getItem('user'));
const initialState: State = {
  user: user,
  isAuthenticated: !!(user && user.token)
}
;

export function authReducer(state = initialState, action: AuthActions.AuthActions) {
  switch (action.type) {
    case (AuthActions.SIGN_UP):
    case (AuthActions.LOGIN):
      localStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true
      };
    case (AuthActions.LOGOUT):
      localStorage.removeItem('user');
      return {
        ...state,
        user: null,
        isAuthenticated: false
      };
    default:
      return state;
  }
}
