import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isToggled: boolean;

  constructor(private route: ActivatedRoute) {
    this.isToggled = false;
  }

  ngOnInit() {
  }

  menuTrigger(type) {
    this.isToggled = type;
  }
}
