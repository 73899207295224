import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Store} from '@ngrx/store';
import * as fromApp from '../../store/app.reducers';
import * as fromAuth from '../../store/auth.reducers';
import {switchMap, take, timeout} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  readonly defaultTimeout = 45 * 1000; // Seconds * 1000

  constructor(private store: Store<fromApp.AppState>) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select('auth')
      .pipe(take(1),
        switchMap((authState: fromAuth.State) => {
          // Clone the request to add the new header.
          let interceptedRequest = req.clone({headers: req.headers.set('Content-Type', 'application/json')});
          if (authState.isAuthenticated) {
            const authHeader = 'Bearer ' + authState.user.token;
            interceptedRequest = req.clone({headers: req.headers.set('Authorization', authHeader)});
          }
          return next.handle(interceptedRequest).pipe(
            timeout(this.defaultTimeout)
          );
        }));
  }
}
