import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AppLoader} from "./app-loader";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit, OnChanges {

  @Input() appLoader = new AppLoader();

  constructor() {
  }

  ngOnInit() {
  }

  setStyles() {
    const styles = {
      'z-index': this.appLoader.visibility ? '100' : '-100',
    };
    return styles;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['appLoader']) {
      if (changes['appLoader'].currentValue !== changes['appLoader'].previousValue) {
        this.appLoader = changes['appLoader'].currentValue;
      }
    }
  }
}
