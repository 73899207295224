import {Component, OnInit, ViewEncapsulation} from '@angular/core';

declare var $: any;

@Component({
  selector: 'popup',
  templateUrl: './popup.html',
  styleUrls: ['./popup.css'],
  encapsulation: ViewEncapsulation.None
})
export class PopupComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  //  this.openModal();
  }
  // openModal() {
  //   console.log("hellooooooo i ampressing");
    
  //   $('#myModal').modal('show');
  // }

  // closeModal() {
  //   $('#myModal').modal('hide');
  // }
   
  displayStyle = "block"; 
  closePopup() { 
    this.displayStyle = "none"; 
  } 
}
