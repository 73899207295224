import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ApiResponse} from '../../models/api.response';
import {AppConfig} from '../../../config/app.config';
import {Pagination} from '../../models/pagination';
import {Filter} from '../../models/filter';
import {City} from '../../models/city';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  constructor(public http: HttpClient) {
  }

  getCity(id): Observable<ApiResponse<City>> {
    const url = `${AppConfig.URL}/api/cities/${id}`;
    return this.http.get<ApiResponse<City>>(url);
  }

  getCites(filter: Filter = {}, sort: string = '', pageNumber: number = 0, limit: number = 0): Observable<ApiResponse<Pagination<City>>> {
    const url = `${AppConfig.URL}/api/cities?filter=${JSON.stringify(filter)}&page=${pageNumber}&limit=${limit}&sort=${sort}`;
    return this.http.get<ApiResponse<Pagination<City>>>(url);
  }

  createCity(city: City): Observable<ApiResponse<City>> {
    const url = `${AppConfig.URL}/api/cities`;
    return this.http.post<ApiResponse<City>>(url, city);
  }

  updateCity(id, city: City): Observable<ApiResponse<City>> {
    const url = `${AppConfig.URL}/api/cities/${id}`;
    return this.http.patch<ApiResponse<City>>(url, city);
  }
}
