import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {AppError} from "./app-error";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit, OnChanges {

  @Input() appError = new AppError();

  constructor() {
  }

  ngOnInit() {
  }

  setStyles() {
    const styles = {
      'z-index': this.appError.visibility ? '100' : '-100',
    };
    return styles;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['appError']) {
      if (changes['appError'].currentValue !== changes['appError'].previousValue) {
        this.appError = changes['appError'].currentValue;
      }
    }
  }

}
