import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: '../app/admin/admin.module#AdminModule'
  },
  // otherwise redirect to home
  {path: '**', loadChildren: '../app/web/web.module#WebModule'}

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})


export class AppRoutingModule {
}
