import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NgxScrollToService} from '../../core/services/ngx-scroll-to.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit {
  private fragment: string;

  constructor(
    private route: ActivatedRoute,
    private ngxScrollToService: NgxScrollToService) {
  }

  ngOnInit() {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    });
  }

  ngAfterViewInit(): void {
    this.ngxScrollToService.triggerScrollTo('#' + this.fragment, 10);
  }

}
