import {Component, OnInit} from '@angular/core';
import {CityService} from '../../core/services/city.service';
import {City} from '../../models/city';
import {ApiResponse} from '../../models/api.response';
import {Pagination} from '../../models/pagination';
import {Filter} from '../../models/filter';
import {HttpController} from '../../core/httpHelpers/http.controller';
import {UserRoleEnum} from '../../enum/user-role.enum';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  rows: City[] = [];
  flyingTo = 'loading';

  constructor(private cityService: CityService) {
  }

  ngOnInit() {
    this.getCities();
  }

  getCities(): void {
    const filter = {active: {match: true}};
    this.cityService.getCites(filter, 'name')
      .subscribe(new class extends HttpController <ApiResponse<Pagination<City>>> {
          onComplete(): void {

          }

          onError(errorMessage: string, err: any) {
            this.context.errorMessage = errorMessage;
          }

          onNext(apiResponse: ApiResponse<Pagination<City>>): void {
            console.log(apiResponse);
            this.context.flyingTo = '';
            for (let i = 0; i < apiResponse.data.list.length; i++) {
              this.context.flyingTo += apiResponse.data.list[i]['name'] + ' - ';
            }
            this.context.flyingTo = this.context.flyingTo.replace(/-\s+$/g, '');
          }
        }(this)
      );
  }
}
