// Created by soban on 29-08-2018.
import {Observer} from 'rxjs';
import {AppConfig} from '../../../config/app.config';
import {AppLoader} from '../loader/app-loader';
import {AppError} from '../error/app-error';

export abstract class HttpController<T> implements Observer<T> {
  private appLoader: AppLoader;
  private appError: AppError;

  constructor(protected context: any = null, appLoader?: AppLoader, appError?: AppError) {
    this.appLoader = appLoader;
    this.appError = appError;
    this.initialize();
  }

  private initialize(): void {
    if (this.appLoader) {
      this.appLoader.visibility = true; // show loader
    }
    if (this.appError) {
      this.appError.visibility = false; // hide error
    }
  }

  next(t: T): void {
    if (AppConfig.DEBUG) {
      console.log(t);
    }
    this.onNext(t);
  }

  error(err: any): void {
    if (AppConfig.DEBUG) {
      console.error(err);
    }
    let errorMessage = 'Unfortunately, some error occurred. Please try again!';
    if (err.name === 'TimeoutError') {
      errorMessage = 'Timeout has occurred';
    } else {
      try {
        errorMessage = err.error.message;
      } catch (e) {

      }
    }
    if (this.appLoader) {
      this.appLoader.visibility = false; // hide loader
    }
    if (this.appError) {
      this.appError.visibility = true; // show error
      this.appError.message = errorMessage;
    }
    this.onError(errorMessage, err);
  }

  complete(): void {
    if (this.appLoader) {
      this.appLoader.visibility = false; // hide loader
    }
    this.onComplete();
  }

  abstract onNext(t: T): void;

  abstract onError(errorMessage: string, err: any): void;

  abstract onComplete(): void;
}
